const Status = {
  created: "Registered",
  registered: "Registered",
  approved: "Approved",
  rejected: "Rejected",
  FindingTrips: "Online",

  Online: "En ligne",
  Offline: "Hors ligne",
  true: "Yes",
  false: "No",
  yes: "Yes",
  no: "No",

  OnPoolTrip: "En voyage en piscine",
  OnTrip: "En voyage",
  DestinationInroute: "À destination",
  PickupInroute: "En route",
  PickupArrived: "Pickup Arrived",
  approved: "Approuvé",
  created: "Nouveau",
  rejected: "Rejeté",
  active: "Active",
  inactive: "En Actif",
  Cancelled: "Annulé",
  Completed: "Terminé",
  Scheduled: "Planifié",
  FindingDrivers: "Recherche",
  WatingForDriver: "Attente",
  Scheduled: "Planifié",
  WatingForDriver: "Attente",
  0: "Sun",
  1: "Mon",
  2: "Tus",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};
export default Status;
