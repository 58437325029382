// Api BASE URLs
// export const BASE_URL_LIVE = "https://peitaxi-api.ondemandcreations.com";
export const BASE_URL_LIVE = "https://api.peionlinetaxi.ca";
// export const BASE_URL_STAGGING = "http://3.12.72.231:5004";
export const BASE_URL_STAGGING = "https://api.peionlinetaxi.ca";

//
export const BASE_URL =
  process.env.REACT_APP_MODE === "live" ? BASE_URL_LIVE : BASE_URL_STAGGING;
export const API_URL =
  process.env.REACT_APP_MODE === "live" ? BASE_URL_LIVE : BASE_URL_STAGGING;

export const API_VERSION = "/api/v1";

// COMMON CONSTANT
export const APP_NAME = "Pei Taxi";
export const PANEL_NAME = "Dispatcher";
export const USER_TYPE = "Dispatcher";
export const API_TOKEN = localStorage.getItem("user_id");
export const VENDOR_ID = localStorage.getItem("_id");
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const Numeric = /^[0-9]+$/;
export const ALPHABET = /^[A-Za-z -]+$/;
export const PASS_VALIDATION_MSG =
  "password should be at least 8 characters, one uppercase, lowercase, special character, numeric value.";

export const DEFAULT_LINK = "/app/requests/list";
// AUTH URL
export const API_LOGIN = "/dispature/login";

// DASHBOARD
export const API_GET_ALL_REQUESTS = "/dispatcher/getTripsByStatus";
export const API_GET_REQUEST_DETAILS = "/dispatcher/trip/";
export const API_ASSIGN_DRIVER = "dispatcher/assignedtriptodrver";
export const API_GET_CARTYPES = "admin/cartypes/";

export const API_GET_PROFILE = "/dispature/profile/";
export const API_UPDATE_PROFILE = "/dispatcher/updateMyprofile/";

export const API_ADD_TRIP = "dispature/addTrip";
export const API_GET_TRIPS = "dispature/getTripByStatus";

export const API_UPADTE_STORE_STATUS = "/store/storeUpdateOnlineOfflineStatus";
export const API_GET_STORE_LOCATION = "/dispature/getAddress/";
export const API_UPADTE_STORE_LOCATION = "/dispature/addAddress";
